<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center justify-md-start"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Igreja de São Luís Rei de França encontra-se em local elevado
                em relação à rua, acessada por uma escadaria ladeada por
                canteiros. A construção é de época recente, com volumetria
                prismática, fachadas simples compostas por portas de madeira
                almofadas no pavimento térreo, encimadas por janelas com
                caixilho metálico e fechamento em vidro, com verga de arco pleno
                e dimensões verticais alongadas. Apresenta duas torres
                simétricas cobertas por telhas cerâmicas e uma cruz em cada.
              </p>
              <p>
                Além do núcleo urbano, o distrito é conformado por sítios e
                fazendas com produção agrícola e pecuária, destacando-se a
                criação de bovino leiteiro e produção de leite. Ressalta-se que
                no próprio núcleo urbano existem terrenos de maiores extensões
                onde as famílias criam gado entre outros animais, em meio aos
                cultivos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-start align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_aguas_claras_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da fachada frontal da Igreja de São Luís Rei de França, Águas
            Claras. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Localizado em uma região onde a atividade pecuária e produtora
                de leite é extremamente significativa econômica e socialmente,
                no distrito destaca-se a Associação dos produtores de leite de
                Águas Claras e região. A produção das fazendas das comunidades é
                direcionada para a sede da associação, que distribui para os
                laticínios. Outra atividade bastante desenvolvida é o cultivo de
                eucalipto e produção de carvão vegetal em fornos de encosta.
              </p>
              <p>
                Águas Claras é conhecida pelas belezas naturais de suas trilhas
                ecológicas e seu famoso artesanato em taquara. O distrito atrai
                visitantes com a conhecida Cachoeira do “Ó”, localizada no
                subdistrito de Campinas, queda d’água com aproximadamente 6
                metros de altura que forma um poço de 5 metros de profundidade.
                Suas margens com bancos de areia formam uma praia de rio onde as
                pessoas faziam seus piqueniques aos fins de semana.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-start align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_aguas_claras_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de uma extensão de terras com plantio de Eucalipto tendo em
            cotas mais baixas os fornos de encosta para queima do carvão. Foto:
            Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_aguas_claras_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Cachoeira do Ó, situada no subdistrito de Campinas, com suas margens
            de areia. Foto: Paula Zanardi, 2021.
          </p>
          <img
            width="100%"
            src="../../assets/sobre_cultura_aguas_claras_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista externa de um dos estabelecimentos comerciais do distrito.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os moradores de Águas Claras frequentam ainda as festividades
                tradicionais no subdistrito de Pedras e possuíam vínculos com
                Paracatu de Baixo. Da mesma forma que a população de Águas
                Claras se dirigia a Paracatu nas ocasiões festivas, os
                habitantes daquela comunidade também frequentavam o distrito.
                Além das trocas culturais e do convívio social, havia com a
                comunidade de Paracatu relações comerciais e de prestação de
                serviços, interrompidas após o rompimento da barragem de Fundão.
                Soma-se a isso, a impossibilidade que muitos produtores se
                depararam de continuar realizando suas atividades a exemplo da
                produção de mel em sítios próximos ao Rio Gualaxo do Norte.
                Diante da destruição de pontes e estradas, Águas Claras
                permaneceu sem acesso durante um período, não havendo meios dos
                produtores escoar a produção de leite e derivados, causando
                prejuízos e prejudicando a continuidade de suas atividades, cuja
                retomada foi bastante custosa, quando não abandonada.
              </p>
              <p>
                Em meio às paisagens naturais e arquitetônicas, o distrito segue
                com suas tradicionais festas religiosas, como a Festa do
                Padroeiro de São Luis de França, a mais conhecida e celebrada.
                Entre os dias 16 e 25 de agosto, é celebrada a novena e festa em
                louvor ao Santo, incluindo na programação oração diária ao
                ngelus e celebração de missa. No dia festivo, 25 de agosto, há
                também celebração de missa e realização de leilão. Da culinária
                local destacam-se os doces preparados pelos moradores,
                comercializados para turistas.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira <sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
